function addLikeListeners() {
    $(".like-button").on("ajax:success", (event) => {
        const [_data, _status, xhr] = event.detail
        const data = JSON.parse(xhr.responseText)

        const likeButtonId = "like-button-" + data.post_id
        document.getElementById(likeButtonId).innerHTML = data.new_like_button

        const likeCountId = "like-count-" + data.post_id
        document.getElementById(likeCountId).innerHTML = data.likes_text
    })
}

$(document).on("turbolinks:load", addLikeListeners)