// this adds listeners to each comment submit form
// it may not work for posts loaded after the initial page load.
// so this may need to be put in a function and rerun on
// pagination loaded events.

function addCommentListeners() {
    const commentForms = $(".comment-form")
    commentForms.on("ajax:send", (event) => {
        $(event.target).find(".comment-error").remove()
    })

    commentForms.on("ajax:success", (event) => {
        const [_data, _status, xhr] = event.detail
        const data = JSON.parse(xhr.responseText)

        const post = $("#post-" + data.post_id)
        post.find(".comment-section").append(data.new_comment)

        event.target.reset()

        const commentForm = post.find(".comment-form")
        const input = commentForm.find("input.comment-text")
        input.attr("placeholder", "Add another comment...")
        input.val("")
        input.blur()
        // BUG: this should disable the submit button but it isn't
        commentForm.find("[type=submit]").prop("disabled", true)
    })

    commentForms.on("ajax:error", (event) => {
        const [_data, _status, xhr] = event.detail
        const data = JSON.parse(xhr.responseText)

        const post = $("#post-" + data.post_id)
        post.find(".comment-form").prepend(data.errors)
    })

    // jquerify this somehow
    $(".comment-text").on("input", (event) => {
        const targetInput = event.target.closest("form").querySelector("input.comment-text")
        const submitButton = event.target.closest("form").querySelector("[type=submit]")
        submitButton.disabled = targetInput.value === ""
    })
}

$(document).on("turbolinks:load", addCommentListeners)
