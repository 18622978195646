function addMediaPreviewListeners() {
    let mediaPreview = $("#media-preview img")

    $("#post_media").change(function(event) {
        let input = $(event.currentTarget)
        let file = input[0].files[0]
        let reader = new FileReader()
        reader.onload = function(e) {
            image_base64 = e.target.result
            mediaPreview.attr("src", image_base64)
        }
        reader.readAsDataURL(file)
        $("#upload-text").hide()
    })
}

$(document).on("turbolinks:load", function() {
    addMediaPreviewListeners()
    $('[data-toggle="tooltip"]').tooltip()
})